//
// Base styles
//

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
  word-wrap: break-word;
  background-color: var(--card-bg-color);
  background-clip: border-box;
  border: var(--card-border-width) solid var(--card-border-color);
  border-radius: var(--card-border-radius);
  box-shadow: var(--card-box-shadow);
  color: var(--card-text-color);

  .card-preloader {
    border-radius: var(--card-border-radius);
  }

  >hr {
    margin-right: 0;
    margin-left: 0;
  }
}

.card-tabs {
  padding: 0 var(--card-spacer-x);
  background-color: var(--card-tabs-bg-color);
}

.card-body {
  // Enable `flex-grow: 1` for decks and groups so that card blocks take up
  // as much space as possible, ensuring footers are aligned to the bottom.
  flex: 1 1 auto;
  padding: calc(var(--card-spacer-y) - var(--card-border-width)) calc(var(--card-spacer-x) - var(--card-border-width));
  color: var(--card-text-color);
}

.card-title {
  margin-bottom: var(--card-spacer-y);
}

.card-subtitle {
  margin-top: calc(var(--card-spacer-y) * -.5);
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link {
  +.card-link {
    margin-left: var(--card-spacer-x);
  }
}

//
// Optional textual caps
//

.card-header {
  padding: calc(var(--card-spacer-y) * .5) var(--card-spacer-x);
  margin-bottom: 0; // Removes the default margin-bottom of <hN>
  color: var(--card-header-text-color);
  background-color: var(--card-header-bg-color);
  border-bottom: var(--card-border-width) solid var(--card-border-color);

  &:first-child {
    border-radius: calc(var(--card-border-radius) - var(--card-border-width)) calc(var(--card-border-radius) - var(--card-border-width)) 0 0;
  }
}

.card-footer {
  padding: calc(var(--card-spacer-y) * .5) var(--card-spacer-x);
  color: var(--card-footer-text-color);
  background-color: var(--card-footer-bg-color);
  border-top: var(--card-border-width) solid var(--card-border-color);

  &:last-child {
    border-radius: 0 0 calc(var(--card-border-radius) - var(--card-border-width)) calc(var(--card-border-radius) - var(--card-border-width));
  }
}

.card>.table,
.table-in-card {

  // stylelint-disable-next-line selector-max-universal
  > :not(caption)>*>* {
    &:first-child {
      padding-left: var(--card-spacer-x);
    }

    &:last-child {
      padding-right: var(--card-spacer-x);
    }
  }
}

.card {
  >.table {
    &:first-child {
      tr:first-child {
        td:first-child {
          border-bottom-left-radius: var(--card-border-radius);
        }

        td:last-child {
          border-bottom-right-radius: var(--card-border-radius);
        }
      }
    }

    &:last-child {
      tr:last-child {
        td:first-child {
          border-bottom-left-radius: var(--card-border-radius);
        }

        td:last-child {
          border-bottom-right-radius: var(--card-border-radius);
        }
      }
    }
  }
}

.card {
  >aeb-list-group {
    --list-group-item-padding-x: var(--card-spacer-x);
    --list-group-border-radius: var(--card-border-radius);
  }
}

.card-tabs {
  >.nav-tabs {
    --nav-link-color: var(--card-tabs-link-color);
    --nav-tabs-items-gap: 0.75rem;
    --nav-tabs-link-padding-x: 0.78125rem;
    --nav-tabs-link-padding-y: 1rem;
    --nav-tabs-link-border-width: 6px;
    --nav-tabs-link-active-color: var(--card-tabs-link-active-color);

    margin-left: -0.25rem;
    margin-right: -0.25rem;
    padding-bottom: var(--nav-tabs-link-border-width);

    .nav-link {
      font-weight: normal;
      text-transform: none;
    }
  }
}

.card-preloader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: $zindex-blocking-loader;
  transform: translate3d(0, 0, 0);
  overflow: hidden;

  aeb-backdrop {
    --backdrop-width: 100%;
    --backdrop-height: 100%;
  }
}
