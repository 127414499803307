dl {
  padding-left: 2rem;
  list-style: decimal;
  counter-reset: item;
}

dt {
  display: list-item;
  counter-increment: item;
}

dd {
  margin-top: 1rem;
  margin-bottom: 1rem;

  dt {
    counter-increment: item;

    &::marker {
      content: counters(item, ".") ". ";
    }
  }

  ol {
    counter-reset: item;

    > li {
      counter-increment: item;

      &::marker {
        content: counters(item, ".") ". ";
      }
    }
  }
}
