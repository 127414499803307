:root {
  --card-bg-color: #FFFFFF;
  --card-border-color: transparent;
  --card-text-color: var(--text-color-3);
  --card-border-width: 0px;
  --card-border-radius: var(--border-radius);

  --bicolumn-card-description-bg-color: #F9FAFB;
  --bicolumn-card-description-width: 22.125rem;
  --bicolumn-card-description-padding-x: 2rem;
  --bicolumn-card-description-padding-y: 2rem;
  --bicolumn-card-content-padding-x: 2rem;
  --bicolumn-card-content-padding-y: 2rem;
  --bicolumn-card-content-actions-gap: 1.5rem;

  --card-spacer-y: 1.5rem;
  --card-spacer-x: 1.5rem;

  --card-box-shadow: var(--elevation-level-1);

  --card-header-bg-color: rgba(0, 0, 0, 0.03);
  --card-header-text-color: var(--body-color);

  --card-tabs-bg-color: rgba(0, 0, 0, 0.03);
  --card-tabs-link-color: var(--body-color);
  --card-tabs-link-active-color: var(--card-tabs-link-color);

  --card-footer-bg-color: var(--card-header-bg-color);
  --card-footer-text-color: var(--card-text-color);

  --empty-card-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 160 150'><path fill='%23f6f6f6' d='M155 75a75 75 0 0 1-75 75A75 75 0 0 1 5 75 75 75 0 0 1 80 0a75 75 0 0 1 75 75Z'/><path fill='%23fff' d='M6 22h148c3.324 0 6 2.676 6 6v98c0 3.324-2.676 6-6 6H6c-3.324 0-6-2.676-6-6V28c0-3.324 2.676-6 6-6z'/><path fill='none' stroke='%23707070' stroke-width='2' d='M90.59 92.681c-3.572 7.157-13.1 6.15-15.761 4.669'/><path fill='%2306b6d4' d='M65.986 69.781998a4 4 0 0 1-4 4 4 4 0 0 1-4-4 4 4 0 0 1 4-4 4 4 0 0 1 4 4zM98.846001 67.695a4 4 0 0 1-4 4 4 4 0 0 1-4-4 4 4 0 0 1 4-4 4 4 0 0 1 4 4z'/><path fill='%23c1c1c1' d='M6 22c-3.3101588 0-6 2.689841-6 6v98c0 3.31016 2.6898412 6 6 6h148c3.31016 0 6-2.68984 6-6V28c0-3.310159-2.68984-6-6-6H6zm0 1.599609h148c2.45144 0 4.40039 1.948956 4.40039 4.400391v11.699219H1.5996094V28c0-2.451435 1.9489554-4.400391 4.4003906-4.400391zM1.5996094 41.300781H158.40039V126c0 2.45144-1.94895 4.40039-4.40039 4.40039H6c-2.4514352 0-4.4003906-1.94895-4.4003906-4.40039V41.300781z' color='%23000' /></svg>");
}
