//
// Headings
//

h1 {
  font-weight: 700;
}

h2 {
  font-weight: 700;
}

h3 {
  font-weight: 500;
}

h4 {
  font-weight: 600;
}

h5 {
  font-weight: 500;
}

h6 {
  font-weight: 500;
}
