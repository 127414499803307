.form-input-number {
  position: relative;

  &__input {
    padding-right: 5rem;
  }

  &__controls {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 0.625rem;
  }

  &__of {
    pointer-events: none;
    font-size: $input-font-size;
    color: var(--form-input-number-of-color);
  }

  &__buttons {
    display: flex;
    align-items: stretch;
    justify-content: stretch;
    flex-direction: column;
    height: 100%;
    padding-top: calc(#{$input-border-width} + 0.125rem);
    padding-bottom: calc(#{$input-border-width} + 0.125rem);
    padding-right: calc(#{$input-border-width} + 0.125rem);
  }

  &__button {
    flex-basis: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    line-height: 0;
    padding: 0;
    border: 0;
    background-color: var(--form-input-number-button-bg);
    color: var(--form-input-number-button-color);

    .heroicon {
      width: 0.825rem;
    }

    &:not(:disabled) {
      &:hover {
        background-color: var(--form-input-number-button-hover-bg);
        color: var(--form-input-number-button-hover-color);
      }

      &:focus,
      &:active {
        background-color: var(--form-input-number-button-focus-bg);
        color: var(--form-input-number-button-focus-color);
      }
    }

    &.increase-btn {
      border-top-left-radius: calc(var(--input-border-radius) * 0.5);
      border-top-right-radius: calc(var(--input-border-radius) * 0.5);
    }

    &.decrease-btn {
      border-bottom-left-radius: calc(var(--input-border-radius) * 0.5);
      border-bottom-right-radius: calc(var(--input-border-radius) * 0.5);
    }
  }
}
