:root {
  --order-event-report-status-success-bg: var(--badge-success-bg-color);
  --order-event-report-status-success-color: var(--badge-success-text-color);

  --order-event-report-status-warning-bg: var(--badge-warning-bg-color);
  --order-event-report-status-warning-color: var(--badge-warning-text-color);

  --order-event-report-status-failure-bg: var(--badge-danger-bg-color);
  --order-event-report-status-failure-color: var(--badge-danger-text-color);
}
