:root {
  --payout-transaction-report-status-queued-bg: var(--badge-pending-bg-color);
  --payout-transaction-report-status-queued-color: var(--badge-pending-text-color);

  --payout-transaction-report-status-bookkept-bg: var(--badge-success-bg-color);
  --payout-transaction-report-status-bookkept-color: var(--badge-success-text-color);

  --payout-transaction-report-status-bookkept-to-interim-account-bg: var(--badge-warning-bg-color);
  --payout-transaction-report-status-bookkept-to-interim-account-color: var(--badge-warning-text-color);

  --payout-transaction-report-status-bookkept-with-warning-bg: var(--badge-warning-bg-color);
  --payout-transaction-report-status-bookkept-with-warning-color: var(--badge-warning-text-color);

  --payout-transaction-report-status-failed-to-bookkeep-bg: var(--badge-danger-bg-color);
  --payout-transaction-report-status-failed-to-bookkeep-color: var(--badge-danger-text-color);

  --payout-transaction-report-status-duplicate-bg: var(--badge-neutral-bg);
  --payout-transaction-report-status-duplicate-color: var(--badge-neutral-color);

  --payout-transaction-report-status-corrected-bg: #5BCC9F;
  --payout-transaction-report-status-corrected-color: #FFFFFF;
}