:root {
  --invoice-report-status-success-bg: var(--badge-success-bg-color);
  --invoice-report-status-success-color: var(--badge-success-text-color);

  --invoice-report-status-warning-bg: var(--badge-warning-bg-color);
  --invoice-report-status-warning-color: var(--badge-warning-text-color);

  --invoice-report-status-failure-bg: var(--badge-danger-bg-color);
  --invoice-report-status-failure-color: var(--badge-danger-text-color);

  --invoice-report-status-payment-success-bg: var(--invoice-report-status-success-bg);
  --invoice-report-status-payment-success-color: var(--invoice-report-status-success-color);

  --invoice-report-status-payment-failure-bg: var(--invoice-report-status-failure-bg);
  --invoice-report-status-payment-failure-color: var(--invoice-report-status-failure-color);

  --invoice-report-status-pending-bg: var(--badge-pending-bg-color);
  --invoice-report-status-pending-color: var(--badge-pending-text-color);

  --invoice-report-status-requires-attention-bg: var(--badge-warning-bg-color);
  --invoice-report-status-requires-attention-color: var(--badge-warning-text-color);

  --invoice-report-status-corrected-bg: #5BCC9F;
  --invoice-report-status-corrected-color: #FFFFFF;
}
