:root {
  --headings-margin-bottom: 0.5rem;
  --headings-line-height: 1.22;
  --headings-color: var(--text-color-2);

  --h1-font-size: 1.75rem;
  --h2-font-size: 1.5rem;
  --h3-font-size: 1.25rem;
  --h4-font-size: 1.125rem;
  --h5-font-size: 1rem;
  --h6-font-size: 0.875rem;
}
