// Select
//
// Replaces the browser default select with a custom one, mostly pulled from
// https://primer.github.io/.

.form-select {
  display: block;
  width: 100%;
  padding: $form-select-padding-y $form-select-indicator-padding $form-select-padding-y $form-select-padding-x;
  // stylelint-disable-next-line property-no-vendor-prefix
  -moz-padding-start: subtract($form-select-padding-x, 3px); // See https://github.com/twbs/bootstrap/issues/32636
  font-size: $form-select-font-size;
  font-weight: $form-select-font-weight;
  line-height: $form-select-line-height;
  color: var(--form-select-color);
  background-color: var(--form-select-bg);
  background-image: var(--form-select-indicator-bg-image);
  background-repeat: no-repeat;
  background-position: $form-select-bg-position;
  background-size: $form-select-bg-size;
  border: $form-select-border-width solid var(--form-select-border);
  border-radius: var(--form-select-border-radius);
  box-shadow: var(--form-select-box-shadow);
  @include transition($form-select-transition);
  appearance: none;

  min-height: 2.625rem;

  &:focus {
    border-color: var(--form-select-focus-border);
    outline: 0;
  }

  &[multiple],
  &[size]:not([size="1"]) {
    padding-right: $form-select-padding-x;
    background-image: none;
  }

  &.disabled,
  &:disabled {
    border-color: var(--form-select-disabled-border);
    background-color: var(--form-select-disabled-bg);
    color: var(--form-select-disabled-color);
  }

  // Remove outline from select box in FF
  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 var(--form-select-color);
  }
}
