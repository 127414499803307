.toasts {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: var(--toasts-gap);

  position: fixed;
  right: var(--toasts-indent-x);
  bottom: var(--toasts-indent-x);
  z-index: 99999;
}

.toast {
  --toast-padding-x: 1rem;
  --toast-padding-y: 1rem;
  --toast-gap-x: var(--toast-padding-x);
  --toast-gap-y: calc(var(--toast-padding-y) * 0.5);
  --toast-border-radius: 0.375rem;
  --toast-autoclose-progress-height: 0.25rem;

  --toast-bg: var(--toast-default-bg);
  --toast-status-color: var(--toast-default-status-color);
  --toast-heading-color: var(--toast-default-heading-color);
  --toast-content-color: var(--toast-default-content-color);
  --toast-content-link-color: var(--toast-default-content-link-color);
  --toast-close-btn-color: var(--toast-default-close-btn-color);
  --toast-autoclose-progress-bg: var(--toast-default-autoclose-progress-bg);
  --toast-color-scheme: var(--toast-default-color-scheme);

  $this: &;

  position: relative;

  display: inline-grid;
  grid-template-columns: 1.25rem 1fr 1.25rem;
  gap: var(--toast-gap-y) var(--toast-gap-x);

  min-width: 16rem;
  padding: var(--toast-padding-y) var(--toast-padding-x);
  border-radius: var(--toast-border-radius);
  overflow: hidden;
  background-color: var(--toast-bg);
  box-shadow: var(--toast-box-shadow);
  color-scheme: var(--toast-color-scheme);

  &_autoclose {
    cursor: pointer;
  }

  &-status {
    grid-column: 1 / 2;
    grid-row: 1 / 2;
    font-size: 0;
    color: var(--toast-status-color);

    .heroicon {
      width: 1.25rem;
    }
  }

  &-heading {
    grid-column: 2 / 4;
    grid-row: 1 / 2;
    line-height: 1.11111;
    font-size: 1.125rem;
    font-weight: 500;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: var(--toast-heading-color);

    #{$this}_with-close-btn & {
      grid-column: 2 / 3;
    }
  }

  &-close {
    grid-column: 3 / 4;
    grid-row: 1 / 2;
    font-size: 0;

    .heroicon {
      width: 1.25rem;
    }

    &-btn {
      padding: var(--toast-padding-y) var(--toast-padding-x);
      margin: calc(var(--toast-padding-y) * -1) calc(var(--toast-padding-x) * -1);
      border: 0;
      background: none;
      color: var(--toast-close-btn-color);
    }
  }

  &-content {
    grid-column: 2 / 4;
    grid-row: 2 / 3;
    font-size: 0.75rem;
    color: var(--toast-content-color);

    a {
      font-weight: 600;
      color: var(--toast-content-link-color);
    }

    #{$this}_with-close-btn & {
      grid-column: 2 / 3;
    }
  }

  &-autoclose-progress {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: var(--toast-autoclose-progress-height);
    background-color: var(--toast-autoclose-progress-bg);
  }

}

.toast_info {
  --toast-bg: var(--toast-info-bg);
  --toast-status-color: var(--toast-info-status-color);
  --toast-heading-color: var(--toast-info-heading-color);
  --toast-content-color: var(--toast-info-content-color);
  --toast-content-link-color: var(--toast-info-content-link-color);
  --toast-close-btn-color: var(--toast-info-close-btn-color);
  --toast-autoclose-progress-bg: var(--toast-info-autoclose-progress-bg);
  --toast-color-scheme: var(--toast-info-color-scheme);
}

.toast_success {
  --toast-bg: var(--toast-success-bg);
  --toast-status-color: var(--toast-success-status-color);
  --toast-heading-color: var(--toast-success-heading-color);
  --toast-content-color: var(--toast-success-content-color);
  --toast-content-link-color: var(--toast-success-content-link-color);
  --toast-close-btn-color: var(--toast-success-close-btn-color);
  --toast-autoclose-progress-bg: var(--toast-success-autoclose-progress-bg);
  --toast-color-scheme: var(--toast-success-color-scheme);
}

.toast_warning {
  --toast-bg: var(--toast-warning-bg);
  --toast-status-color: var(--toast-warning-status-color);
  --toast-heading-color: var(--toast-warning-heading-color);
  --toast-content-color: var(--toast-warning-content-color);
  --toast-content-link-color: var(--toast-warning-content-link-color);
  --toast-close-btn-color: var(--toast-warning-close-btn-color);
  --toast-autoclose-progress-bg: var(--toast-warning-autoclose-progress-bg);
  --toast-color-scheme: var(--toast-warning-color-scheme);
}

.toast_error {
  --toast-bg: var(--toast-error-bg);
  --toast-status-color: var(--toast-error-status-color);
  --toast-heading-color: var(--toast-error-heading-color);
  --toast-content-color: var(--toast-error-content-color);
  --toast-content-link-color: var(--toast-error-content-link-color);
  --toast-close-btn-color: var(--toast-error-close-btn-color);
  --toast-autoclose-progress-bg: var(--toast-error-autoclose-progress-bg);
  --toast-color-scheme: var(--toast-error-color-scheme);
}
