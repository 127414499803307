//
// General form controls (plus a few specific high-level interventions)
//

.form-control {
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-size: $input-font-size;
  font-weight: $input-font-weight;
  line-height: $input-line-height;
  color: var(--input-color);
  background-color: var(--input-bg);
  background-clip: padding-box;
  border: $input-border-width solid var(--input-border);
  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  border-radius: var(--input-border-radius);
  box-shadow: var(--input-box-shadow);
  appearance: none; // Fix appearance for date inputs in Safari

  min-height: 2.625rem;

  @include transition($input-transition);

  // Customize the `:focus` state to imitate native WebKit styles.
  &:focus {
    border-color: var(--input-focus-border);
    background-color: var(--input-focus-bg);
    color: var(--input-focus-color);
    outline: 0;
  }

  // Add some height to date inputs on iOS
  // https://github.com/twbs/bootstrap/issues/23307
  // TODO: we can remove this workaround once https://bugs.webkit.org/show_bug.cgi?id=198959 is resolved
  &::-webkit-date-and-time-value {
    // Multiply line-height by 1em if it has no unit
    height: calc($input-line-height * 1em);
  }

  // Placeholder
  &::placeholder {
    color: var(--input-placeholder-color);
    // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
    opacity: 1;
  }

  // Hide eye icon, we use custom show/hide password directive
  &::-ms-reveal {
    display: none;
  }

  // Disabled and read-only inputs
  //
  // HTML5 says that controls under a fieldset > legend:first-child won't be
  // disabled if the fieldset is disabled. Due to implementation difficulty, we
  // don't honor that edge case; we style them as disabled anyway.
  &.disabled,
  &:disabled,
  &[readonly] {
    border-color: var(--input-disabled-border);
    background-color: var(--input-disabled-bg);
    color: var(--input-disabled-color);
    // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
    opacity: 1;
  }
}

// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: $input-padding-y 0;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  line-height: $input-line-height;
  color: var(--input-plaintext-color);
  background-color: transparent;
  border: solid transparent;
  border-width: $input-border-width 0;
  box-shadow: var(--input-box-shadow);

  &.form-control-sm,
  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
}

// Make sure textareas don't shrink too much when resized
// https://github.com/twbs/bootstrap/pull/29124
// stylelint-disable selector-no-qualifying-type
textarea {
  &.form-control {
    min-height: $input-height;
  }
}
// stylelint-enable selector-no-qualifying-type

.form-control-with-icons {
  position: relative;

  display: flex;
  flex-direction: row;
  align-items: stretch;

  .icons {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 4; // should be bigger than z-index for '.input-group > .form-control-with-icons > .form-control:focus'

    display: flex;
    flex-direction: row;
    align-items: stretch;
    justify-content: flex-end;
    padding: 0 0.25rem;

    color: var(--text-color-3);

    heroicon,
    svg-icon {
      display: flex;
      align-items: center;
      width: 2rem;
      padding: 0 0.25rem;
    }
  }
}
