:root {
  --dashboard-logo-h-alignment: flex-start;
  --dashboard-logo-h-indent: 1.125rem;
  --dashboard-logo-v-alignment: center;
  --dashboard-logo-v-indent: 0;

  --dashboard-logo-width: 12.38rem;
  --dashboard-logo-height: 1.75rem;

  --dashboard-header-height: 4.3125rem;

  --dashboard-header-bg: #FFFFFF;
  --dashboard-header-border: transparent;
  --dashboard-header-border-width: 0;
  --dashboard-header-box-shadow: var(--elevation-level-1);

  --dashboard-menu-link-color: var(--text-color-3);
  --dashboard-menu-link-hover-color: #4B5563;
  --dashboard-menu-link-active-color: #111827;

  --dashboard-header-company-chooser-icon-color: #ACACAC;
  --dashboard-header-company-chooser-dropdown-item-active-border: var(--primary);
  --dashboard-header-company-chooser-dropdown-item-hover-bg: #FBFBFB;
  --dashboard-header-form-select-indicator-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8'><path fill='#{str-replace('#9CA3AF', "#", "%23")}' d='M6.00011 8L0.342773 2.34267L2.22944 0.457336L6.00011 4.22934L9.77077 0.457336L11.6574 2.34267L6.00011 8Z'/></svg>");

  --dashboard-user-profile-btn-bg: #9CA3AF;
  --dashboard-user-profile-btn-color: #FFFFFF;
  --dashboard-user-profile-btn-hover-bg: #{darken(#9CA3AF, 12.5%)};
  --dashboard-user-profile-btn-hover-color: var(--dashboard-user-profile-btn-color);
  --dashboard-user-profile-btn-active-bg: #{darken(#9CA3AF, 25%)};
  --dashboard-user-profile-btn-active-color: var(--dashboard-user-profile-btn-color);

  --dashboard-notifications-btn-bg: #F3F4F6;
  --dashboard-notifications-btn-color: #9CA3AF;
  --dashboard-notifications-btn-hover-bg: var(--dashboard-notifications-btn-bg);
  --dashboard-notifications-btn-hover-color: #{darken(#9CA3AF, 12.5%)};
  --dashboard-notifications-btn-active-bg: var(--dashboard-notifications-btn-bg);
  --dashboard-notifications-btn-active-color: #{darken(#9CA3AF, 25%)};


  --dashboard-aside-expanded-width: 21rem;
  --dashboard-aside-collapsed-width: 4.5rem;
  --dashboard-aside-padding: 2.5rem 0.625rem 0 0.625rem;
  --dashboard-aside-bg: var(--dashboard-header-bg);
  --dashboard-aside-border: var(--border-color);

  --dashboard-aside-nav-item-font-size: 1.125rem;
  --dashboard-aside-nav-item-icon-size: 2rem;
  --dashboard-aside-nav-item-bg: transparent;
  --dashboard-aside-nav-item-color: #4B5563;
  --dashboard-aside-nav-item-border-radius: var(--border-radius);
  --dashboard-aside-nav-item-icon-color: #9CA3AF;
  --dashboard-aside-nav-item-active-bg: #F3F4F6;
  --dashboard-aside-nav-item-active-color: #111827;
  --dashboard-aside-nav-item-active-icon-color: var(--text-color-3);
  --dashboard-aside-nav-divider-color: #F3F3F3;

  --dashboard-aside-toggle-mode-btn-color: #CECECE;

  --dashboard-gap: 3.4375rem;

  --dashboard-no-companies-icon: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 96 87.184'><path fill='#{str-replace('#22D3EE', "#", "%23")}' d='m78.038 40.844c-0.8317 0.1027-1.6788 0.1556-2.5382 0.1556-10.36 0-18.925-7.6848-20.306-17.665h-44.553c-1.959 0-3.5472 1.5882-3.5472 3.5472v17.736h70.944zm7.0943-2.2439c6.4664-3.4483 10.868-10.26 10.868-18.1 0-11.322-9.1782-20.5-20.5-20.5-5.0072 0-9.5952 1.7952-13.155 4.7771-1.2985-1.6589-3.3196-2.7251-5.5896-2.7251h-28.378c-3.9181 0-7.0943 3.1763-7.0943 7.0944v7.0944h-10.642c-5.8771 0-10.642 4.7644-10.642 10.642v60.302h85.132zm-28.377-26.412v-3.0417h-28.378v7.0944h27.065c0.2979-1.4098 0.7407-2.7662 1.3121-4.0527zm2.5372 8.312c0 8.9513 7.2564 16.208 16.208 16.208 8.9512 0 16.208-7.2564 16.208-16.208s-7.2564-16.208-16.208-16.208c-8.9513 0-16.208 7.2564-16.208 16.208zm19.172-2.532c0 0.4447-0.1534 0.9047-0.46 1.38-0.3067 0.46-0.7744 0.851-1.403 1.173-0.6134 0.3067-1.38 0.46-2.3 0.46h-0.713l0.46 4.094h2.369l0.138-2.208c0.828-0.1073 1.6176-0.3757 2.369-0.805 0.7513-0.4293 1.357-0.989 1.817-1.679 0.4753-0.69 0.713-1.495 0.713-2.415 0-0.7667-0.1534-1.449-0.46-2.047-0.2914-0.6133-0.7054-1.1347-1.242-1.564-0.5214-0.4447-1.1194-0.782-1.794-1.012-0.6594-0.23-1.357-0.345-2.093-0.345-0.9047 0-1.7097 0.115-2.415 0.345-0.7054 0.23-1.334 0.5597-1.886 0.989s-1.0734 0.9507-1.564 1.564l2.093 1.633c0.414-0.6287 0.9276-1.1117 1.541-1.449 0.6133-0.3527 1.2803-0.529 2.001-0.529 0.5826 0 1.081 0.0997 1.495 0.299 0.4293 0.184 0.759 0.4523 0.989 0.805s0.345 0.7897 0.345 1.311zm-5.083 10.281c0 0.46 0.1763 0.8587 0.529 1.196 0.368 0.3373 0.8203 0.506 1.357 0.506 0.552 0 1.0043-0.1687 1.357-0.506 0.3526-0.3373 0.529-0.736 0.529-1.196s-0.1764-0.8587-0.529-1.196c-0.3527-0.3373-0.805-0.506-1.357-0.506-0.5367 0-0.989 0.1687-1.357 0.506-0.3527 0.3373-0.529 0.736-0.529 1.196zm-66.286 51.841v-28.377h31.925v7.0943h7.0943v-7.0943h31.925v28.377z' clip-rule='evenodd' fill-rule='evenodd'/></svg>");

  --dashboard-widgets-gap: 1.8751rem;

  --widget-bg: var(--card-bg-color);
  --widget-border: var(--card-border-color);
  --widget-color: var(--card-text-color);
  --widget-border-width: var(--card-border-width);
  --widget-border-radius: var(--card-border-radius);
  --widget-spacer-y: var(--card-spacer-y);
  --widget-spacer-x: var(--card-spacer-x);
  --widget-box-shadow: var(--card-box-shadow);

  --chart-bg: var(--widget-bg);
  --chart-color: var(--widget-color);
  --chart-vAxis-gridline-color: #F9F7F9;
  --chart-vAxis-baseline-color: #E5E7EB;
}
