.huge-icon {
  display: inline-flex;
  width: var(--huge-icon-size);
  height: var(--huge-icon-size);
  border: var(--huge-icon-border-width) solid;
  margin-bottom: 2.75rem;
  border-radius: 50%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.huge-icon {
  &_close {
    background-color: var(--huge-icon-close-bg);
    border-color: var(--huge-icon-close-border);
    background-image: var(--huge-icon-close-bg-image);
  }

  &_check {
    background-color: var(--huge-icon-check-bg);
    border-color: var(--huge-icon-check-border);
    background-image: var(--huge-icon-check-bg-image);
  }

  &_plus {
    background-color: var(--huge-icon-plus-bg);
    border-color: var(--huge-icon-plus-border);
    background-image: var(--huge-icon-plus-bg-image);
  }
}
