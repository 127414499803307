.text-link {
  display: inline-flex;
  align-items: center;
  gap: 0.25rem;
  font-size: var(--h5-font-size);
  font-weight: 600;
  text-transform: uppercase;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;

  .heroicon {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
}

button.text-link {
  padding: 0;
  background-color: transparent;
  border: 0;
}
