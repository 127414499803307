:root {
  --nav-link-color: var(--body-color);
  --nav-link-hover-color: var(--primary);
  --nav-link-disabled-color: #999999;

  --nav-tabs-border: transparent;
  --nav-tabs-items-gap: 0;
  --nav-tabs-link-padding-x: 0.75rem;
  --nav-tabs-link-padding-y: 0.25rem;
  --nav-tabs-link-border-width: 2px;
  --nav-tabs-link-hover-border: var(--nav-tabs-border);
  --nav-tabs-link-focus-border: var(--nav-tabs-border);
  --nav-tabs-link-active-color: var(--primary);
  --nav-tabs-link-active-bg: transparent;
  --nav-tabs-link-active-border: hsl(var(--primary-h) var(--primary-s) max(calc(var(--primary-l) - 6%), 0%));

  --nav-pills-link-active-color: #FFFFFF;
  --nav-pills-link-active-bg: var(--primary);
}
