//
// Check/radio
//

.form-check {
  display: block;
  min-height: $form-check-min-height;
  padding-left: $form-check-padding-start;
  margin-bottom: $form-check-margin-bottom;

  .form-check-input {
    float: left;
    margin-left: $form-check-padding-start * -1;
  }
}

.form-check-input {
  width: $form-check-input-width;
  height: $form-check-input-width;
  margin-top: calc((var(--line-height-base) * var(--font-size-base) - $form-check-input-width) * .5); // line-height minus check height
  vertical-align: top;
  background-color: var(--form-check-input-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 2px solid var(--form-check-input-border);
  appearance: none;
  print-color-adjust: exact; // Keep themed appearance for print
  @include transition($form-check-transition);

  &[type="checkbox"] {
    border-radius: var(--form-check-input-border-radius);
  }

  &[type="radio"] {
    // stylelint-disable-next-line property-disallowed-list
    border-radius: 50%;
  }

  &:active {
    filter: brightness(90%);
  }

  &:focus {
    border-color: var(--form-check-input-focus-border);
    outline: 0;
  }

  &:checked {
    background-color: var(--form-check-input-checked-bg);
    border-color: var(--form-check-input-checked-border);

    &[type="checkbox"] {
      background-image: var(--form-check-input-checked-bg-image);
    }

    &[type="radio"] {
      background-image: var(--form-check-radio-checked-bg-image);
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: var(--form-check-input-indeterminate-bg);
    border-color: var(--form-check-input-indeterminate-border);
    background-image: var(--form-check-input-indeterminate-bg-image);
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: $form-check-input-disabled-opacity;
  }

  // Use disabled attribute in addition of :disabled pseudo-class
  // See: https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .form-check-label {
      opacity: $form-check-label-disabled-opacity;
      cursor: default;
    }
  }
}

.form-check:not(.form-switch) {
  .form-check-input {
    cursor: pointer;
    margin-top: calc((var(--line-height-base) * var(--font-size-base) - #{$form-check-input-width}) * 0.5); // line-height minus check height
  }
}


.form-check-label {
  &[for] {
    cursor: pointer;
  }
}

//
// Switch
//

.form-switch {
  padding-left: 0;

  .form-check-input {
    float: none;
    width: var(--form-switch-width);
    height: var(--form-switch-height);
    margin-top: 0;
    vertical-align: bottom;
    border-radius: var(--form-switch-border-radius);
    background-color: var(--form-switch-bg);
    background-image: var(--form-switch-bg-image);
    background-position: left center;
    cursor: pointer;
    @include transition($form-switch-transition);

    &:focus {
      background-image: var(--form-switch-focus-bg-image);
    }

    &:checked {
      background-position: right center;
      background-image: var(--form-switch-checked-bg-image);
    }

    &:checked[type="checkbox"] {
      background-color: var(--form-switch-checked-bg);
    }
  }
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &[disabled],
  &:disabled {
    + .btn {
      pointer-events: none;
      filter: none;
    }
  }
}

.inline-checkboxes {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.block-checkboxes {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
}
