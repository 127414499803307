.flow-steps {
  --flow-steps-completed-label-color: #6B7280;
  --flow-steps-completed-description-color: #6B7280;
  --flow-steps-completed-bg: var(--primary);
  --flow-steps-completed-icon-color: #FFFFFF;
  --flow-steps-completed-border: var(--primary);
  --flow-steps-completed-join-color: var(--primary);

  --flow-steps-active-label-color: #3E3E3E;
  --flow-steps-active-description-color: #6B7280;
  --flow-steps-active-bg: #FFFFFF;
  --flow-steps-active-icon-color: var(--primary);
  --flow-steps-active-border: var(--primary);
  --flow-steps-active-join-color: var(--primary);

  --flow-steps-skipped-label-color: #6B7280;
  --flow-steps-skipped-description-color: #6B7280;
  --flow-steps-skipped-bg: #F2A50C;
  --flow-steps-skipped-icon-color: #FFFFFF;
  --flow-steps-skipped-border: #F2A50C;
  --flow-steps-skipped-join-color: #F2A50C;

  --flow-steps-untouched-label-color: #9CA3AF;
  --flow-steps-untouched-description-color: #9CA3AF;
  --flow-steps-untouched-bg: #FFFFFF;
  --flow-steps-untouched-icon-color: transparent;
  --flow-steps-untouched-border: #D1D5DB;
  --flow-steps-untouched-join-color: #E5E7EB;
}
