// Row
//
// Rows contain your columns.

.row {
  @include make-row();

  > * {
    @include make-col-ready();
  }
}


// Columns
//
// Common styles for small and large grid columns

@include make-grid-columns(
  $grid-columns,
  $grid-gutter-width,
  (
    xs: 0,
  )
);
