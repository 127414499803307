:root {
  --product-sync-status-queued-bg: var(--badge-pending-bg-color);
  --product-sync-status-queued-color: var(--badge-pending-text-color);

  --product-sync-status-success-bg: var(--badge-success-bg-color);
  --product-sync-status-success-color: var(--badge-success-text-color);

  --product-sync-status-warning-bg: var(--badge-warning-bg-color);
  --product-sync-status-warning-color: var(--badge-warning-text-color);

  --product-sync-status-failure-bg: var(--badge-danger-bg-color);
  --product-sync-status-failure-color: var(--badge-danger-text-color);

  --product-sync-status-timeout-bg: var(--badge-danger-bg-color);
  --product-sync-status-timeout-color: var(--badge-danger-text-color);
}
