:root {
  --tags-input-bg: var(--form-control-bg);
  --tags-input-color: var(--form-control-color);
  --tags-input-border: #91F2EA;

  --tags-input-item-bg: var(--primary);
  --tags-input-item-color: #FFFFFF;

  --tags-input-item-hover-bg: var(--tags-input-item-bg);
  --tags-input-item-hover-color: var(--tags-input-item-color);

  // --tags-input-item-disabled-bg: ;
  // --tags-input-item-disabled-color: ;

  --tags-input-add-tag-btn-bg: #FFFFFF;
  --tags-input-add-tag-btn-color: var(--primary);
  --tags-input-add-tag-btn-border: var(--primary);

  --tags-input-add-tag-btn-hover-bg: #FFFFFF;
  --tags-input-add-tag-btn-hover-color: #0D9488;
  --tags-input-add-tag-btn-hover-border: #0D9488;

  --tags-input-add-tag-btn-disabled-bg: #F3F4F6;
  --tags-input-add-tag-btn-disabled-color: #9CA3AF;
  --tags-input-add-tag-btn-disabled-border: #9CA3AF;
}
