:root {
  --list-group-border-radius: var(--border-radius);

  --list-group-hover-bg: #F9FAFB;

  --list-group-item-border-width: 1px;
  --list-group-item-padding-x: 1rem;
  --list-group-item-padding-y: 1rem;

  --list-group-default-bg-color: #FFFFFF;
  --list-group-default-border-color: var(--border-color);
  --list-group-default-heading-color: #111827;
  --list-group-default-text-color: var(--text-color-3);

  --list-group-default-active-bg-color: hsl(var(--primary-h) var(--primary-s) 97%);
  --list-group-default-active-border-color: hsl(var(--primary-h) var(--primary-s) 78%);
  --list-group-default-active-heading-color: hsl(var(--primary-h) var(--primary-s) 17%);
  --list-group-default-active-text-color: hsl(var(--primary-h) var(--primary-s) 25%);

  --list-group-default-disabled-bg-color: var(--list-group-default-bg-color);
  --list-group-default-disabled-border-color: var(--list-group-default-border-color);
  --list-group-default-disabled-heading-color: var(--text-color-3);
  --list-group-default-disabled-text-color: var(--text-color-3);
}
