:root {
  --badge-padding-x: 0.7272em;
  --badge-padding-y: 0.2727em;
  --badge-line-height: 1.27273;
  --badge-border-radius: 99999px;

  --badge-success-bg-color: #12B3A5;
  --badge-success-text-color: #FFFFFF;

  --badge-danger-bg-color: #E62222;
  --badge-danger-text-color: #FFFFFF;

  --badge-warning-bg-color: #FBBF24;
  --badge-warning-text-color: #303030;

  --badge-pending-bg-color: #D8D8D8;
  --badge-pending-text-color: #303030;

  --badge-neutral-bg: #8194A7;
  --badge-neutral-color: #FFFFFF;
}
