// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

// scss-docs-start gray-color-variables
$white: #fff;
$black: #000;
// scss-docs-end gray-color-variables

// Characters which are escaped by the escape-svg function
$escaped-characters: (
  ("<", "%3c"),
  (">", "%3e"),
  ("#", "%23"),
  ("(", "%28"),
  (")", "%29")
);

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

$enable-shadows: true;
$enable-transitions: true;
$enable-reduced-motion: true;
$enable-deprecation-messages: true;
$enable-important-utilities: true;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

// scss-docs-start spacer-variables-maps
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
);
// scss-docs-end spacer-variables-maps

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

// scss-docs-start grid-breakpoints
$grid-breakpoints: (
  xs: 0,
  // sm: 576px,
  // md: 768px,
  // lg: 992px,
  xl: 1200px,
);
// scss-docs-end grid-breakpoints

@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
@include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12;
$grid-gutter-width: 1.5rem;
$grid-row-columns: 6;

$gutters: $spacers;


// Components
$transition-base: all .2s ease-in-out;


// Typography
//
// Font, line-height, and color for body text, headings, and more.
$font-weight-lighter: lighter;
$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-bolder: bolder;
// scss-docs-end font-variables


// scss-docs-start type-variables
$hr-margin-y: $spacer;
// scss-docs-end type-variables


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 0.75rem;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y: $input-btn-padding-y;
$btn-padding-x: 0.9375rem;
$btn-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
// scss-docs-end btn-variables

// Forms

// scss-docs-start form-label-variables
$form-label-margin-bottom: 0.375rem; //.5rem;
$form-label-font-size: 0.875rem; //null;
$form-label-font-style: null;
$form-label-font-weight: 600; //null;
// scss-docs-end form-label-variables

// scss-docs-start form-input-variables
$input-padding-y: $input-btn-padding-y;
$input-padding-x: $input-btn-padding-x;
$input-font-size: 1rem;
$input-font-weight: 400;
$input-line-height: var(--line-height-base);
$input-border-width: 1px;
$input-height: calc($input-line-height * 1em + $input-padding-y * 2 + $input-border-width * 2);

$input-transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

$form-color-width: 3rem;
// scss-docs-end form-input-variables

// scss-docs-start form-check-variables
$form-check-input-width: 1rem;
$form-check-min-height: calc(var(--font-size-base) * var(--line-height-base));
$form-check-padding-start: $form-check-input-width + 0.5rem;
$form-check-margin-bottom: 0;
$form-check-transition: border-color 0.15s ease-in-out, background-color 0.15s ease-in-out;

$form-check-input-disabled-opacity: 0.625;
$form-check-label-disabled-opacity: $form-check-input-disabled-opacity;
// scss-docs-end form-check-variables

// scss-docs-start form-switch-variables
$form-switch-transition: background-position 0.15s ease-in-out;
// scss-docs-end form-switch-variables

// scss-docs-start input-group-variables
$input-group-addon-padding-y: $input-padding-y;
$input-group-addon-padding-x: $input-padding-x;
// scss-docs-end input-group-variables

// scss-docs-start form-select-variables
$form-select-padding-y: $input-padding-y;
$form-select-padding-x: $input-padding-x;
$form-select-font-size: $input-font-size;
$form-select-indicator-padding: $form-select-padding-x * 3; // Extra padding for background-image
$form-select-font-weight: $input-font-weight;
$form-select-line-height: $input-line-height;
$form-select-disabled-color: null;
$form-select-disabled-bg: var(--form-select-disabled-bg);
$form-select-disabled-border-color: var(--form-select-disabled-border);
$form-select-bg-position: right $form-select-padding-x center;
$form-select-bg-size: 16px 12px; // In pixels because image dimensions

$form-select-border-width: $input-border-width;

$form-select-transition: $input-transition;
// scss-docs-end form-select-variables

// Form validation

// scss-docs-start form-validation-states
$form-validation-states: (
  "valid",
  "invalid",
  "warning",
);
// scss-docs-end form-validation-states

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

// scss-docs-start zindex-stack
$zindex-dropdown: 1000;
$zindex-sticky: 1020;
$zindex-fixed: 1030;
$zindex-offcanvas-backdrop: 1040;
$zindex-offcanvas: 1045;
$zindex-modal-backdrop: 1050;
$zindex-modal: 1055;
$zindex-popover: 1070;
$zindex-tooltip: 1080;
// scss-docs-end zindex-stack

// Navs

// scss-docs-start nav-variables
$nav-link-transition: color 0.15s ease-in-out,
  background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
// scss-docs-end nav-variables


// Modals

// scss-docs-start modal-variables
$modal-inner-padding: 2.25rem;

$modal-footer-margin-between: 0.5rem;

$modal-dialog-margin: 1.375rem;
$modal-dialog-margin-y-sm-up: 6.5625rem;

$modal-sm: 300px;
$modal-md: 494px; //440px;
$modal-lg: 800px;
$modal-xl: 1140px;
// scss-docs-end modal-variables
