.icon-btn-in-table {
  --btn-color: var(--icon-btn-in-table-color);
  --btn-hover-color: var(--icon-btn-in-table-color);

  position: relative;
  top: -0.0625rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 0;
  margin: -2rem (-$btn-padding-x);
  border: 0;

  .svg-icon,
  .heroicon {
    width: 1.5rem;
  }
}
