:root {
  --user-profile-dropdown-bg: var(--dropdown-bg);
  --user-profile-dropdown-color: var(--dropdown-color);
  --user-profile-dropdown-name-color: var(--text-color-1);
  --user-profile-dropdown-link-color: var(--text-color-1);

  --user-profile-dropdown-divider-color: var(--dropdown-divider-color);

  --user-profile-dropdown-box-shadow: var(--elevation-level-4);
}
