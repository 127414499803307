// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// @use '@angular/material/datepicker' as datepicker;
// Plus imports for other components in your app.

.cdk-overlay-pane-fullheight {
  height: 100%;
}

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$aeb-primary-palette: (
  50: #dcf2f0,
  100: #a9dfd9,
  200: #6dcac1,
  300: #12b5a7,
  400: #00a595,
  500: #009481,
  600: #008774,
  700: #007764,
  800: #006755,
  900: #004b39,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #FFFFFF,
    400: #FFFFFF,
    500: #FFFFFF,
    600: #FFFFFF,
    700: #FFFFFF,
    800: #FFFFFF,
    900: #FFFFFF,
  )
);

$aeb-accent-palette: (
  50: #e0f6fb,
  100: #b1e9f4,
  200: #7fdaec,
  300: #4bcbe3,
  400: #25c0db,
  500: #06b5d4,
  600: #03a5c1,
  700: #0091a7,
  800: #007d8e,
  900: #005b63,
  contrast: (50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #000000,
    600: #000000,
    700: #000000,
    800: #FFFFFF,
    900: #FFFFFF,
  )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$aeb-primary: mat.m2-define-palette($aeb-primary-palette, 300);
$aeb-accent: mat.m2-define-palette($aeb-accent-palette, 500);

// The warn palette is optional (defaults to red).
$aeb-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$aeb-theme: mat.m2-define-light-theme((color: (primary: $aeb-primary,
        accent: $aeb-accent,
        warn: $aeb-warn,
      )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($aeb-theme);

// @include datepicker.theme($aeb-theme);

// Emit theme-dependent styles for common features used across multiple components.
@include mat.core-theme($aeb-theme);

@include mat.datepicker-theme($aeb-theme);
