:root {
  --table-color: var(--text-color-1);
  --table-border: var(--border-color);

  --table-thead-color: var(--body-color);
  --table-caption-color: var(--text-muted);

  --table-accent-bg: rgba(0, 0, 0, 0.03);
  --table-accent-color: var(--table-color);

  --icon-btn-in-table-color: #707070;

  --table-cell-padding-y: 1.11375rem; // to have 56px row height with 14px font-size
  --table-cell-padding-x: 0.625rem;

  --table-condensed-cell-padding-y: 0.42625rem; // to have 34px row height with 14px font-size
  --table-condensed-cell-padding-x: 0.625rem;

  --table-thead-cell-padding-y: 0.36375rem; // to have 32px row height with 14px font-size
  --table-thead-cell-padding-x: var(--table-cell-padding-x);
}
