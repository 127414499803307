:root {
  --form-text-color: var(--body-bg);
  --form-label-color: var(--text-color-3);

  --form-feedback-valid: var(--success);
  --form-feedback-invalid: var(--danger);
  --form-feedback-warning: var(--warning);

  --input-bg: #FFFFFF;
  --input-border: #D1D5DB;
  --input-border-radius: var(--border-radius);
  --input-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);
  --input-color: var(--text-color-2);
  --input-focus-bg: var(--input-bg);
  --input-focus-border: #98A1AE;
  --input-focus-color: var(--input-color);
  --input-disabled-bg: #F9FAFB;
  --input-disabled-border: var(--input-border);
  --input-disabled-color: #9CA3AF;
  --input-placeholder-color: var(--text-color-2);
  --input-plaintext-color: var(--body-color);
}

.form-check,
.form-switch {
  --form-check-input-bg: var(--input-bg);
  --form-check-input-border: var(--input-border);
  --form-check-input-border-radius: 0.3125rem;
  --form-check-input-focus-border: var(--input-focus-border);
  --form-check-input-checked-bg: var(--primary);
  --form-check-input-checked-border: var(--primary);
  --form-check-input-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'><path fill='#{str-replace('#FFFFFF', "#", "%23")}' d='M9.20741 0.793031C9.39488 0.980558 9.50019 1.23487 9.50019 1.50003C9.50019 1.76519 9.39488 2.0195 9.20741 2.20703L4.20741 7.20703C4.01988 7.3945 3.76557 7.49982 3.50041 7.49982C3.23524 7.49982 2.98094 7.3945 2.79341 7.20703L0.793407 5.20703C0.611249 5.01843 0.510455 4.76583 0.512733 4.50363C0.515012 4.24143 0.62018 3.99062 0.805589 3.80521C0.990997 3.6198 1.24181 3.51463 1.50401 3.51236C1.7662 3.51008 2.0188 3.61087 2.20741 3.79303L3.50041 5.08603L7.79341 0.793031C7.98094 0.60556 8.23524 0.500244 8.50041 0.500244C8.76557 0.500244 9.01988 0.60556 9.20741 0.793031Z'/></svg>");
  --form-check-radio-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='2' fill='#{str-replace('#FFFFFF', "#", "%23")}'/></svg>");
  --form-check-input-indeterminate-bg: #FFFFFF;
  --form-check-input-indeterminate-border: #FFFFFF;
  --form-check-input-indeterminate-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'><path fill='none' stroke='#{str-replace('#14B8A6', "#", "%23")}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/></svg>");
}

.form-switch {
  --form-switch-width: 2.4375rem;
  --form-switch-height: 1.5rem;
  --form-switch-bg: var(--form-check-input-border);
  --form-switch-checked-bg: var(--primary);
  --form-switch-border-radius: var(--form-switch-width);
  --form-switch-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{str-replace('#FFFFFF', "#", "%23")}'/></svg>");
  --form-switch-focus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{str-replace('#FFFFFF', "#", "%23")}'/></svg>");
  --form-switch-checked-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{str-replace('#FFFFFF', "#", "%23")}'/></svg>");
}

.form-select {
  --form-select-bg: var(--input-bg);
  --form-select-border: var(--input-border);
  --form-select-border-radius: var(--input-border-radius);
  --form-select-box-shadow: var(--input-box-shadow);
  --form-select-color: var(--input-color);
  --form-select-disabled-bg: var(--input-disabled-bg);
  --form-select-disabled-border: var(--input-disabled-border);
  --form-select-disabled-color: var(--input-disabled-color);
  --form-select-focus-border: var(--input-focus-border);
  --form-select-indicator-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{str-replace('#2B2B2B', "#", "%23")}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/></svg>");
}

.form-input-number {
  --form-input-number-of-color: #9CA3AF;
  --form-input-number-button-bg: #F3F4F6;
  --form-input-number-button-color: var(--text-color-3);
  --form-input-number-button-hover-bg: #{darken(#F3F4F6, 10%)};
  --form-input-number-button-hover-color: var(--form-input-number-button-color);
  --form-input-number-button-focus-bg: var(--form-input-number-button-hover-bg);
  --form-input-number-button-focus-color: var(--form-input-number-button-hover-color);
}
