:root {
  --payout-summary-bookkeeping-report-status-queued-bg: var(--badge-pending-bg-color);
  --payout-summary-bookkeeping-report-status-queued-color: var(--badge-pending-text-color);

  --payout-summary-bookkeeping-report-status-bookkept-bg: var(--badge-success-bg-color);
  --payout-summary-bookkeeping-report-status-bookkept-color: var(--badge-success-text-color);

  --payout-summary-bookkeeping-report-status-bookkept-with-warning-bg: var(--badge-warning-bg-color);
  --payout-summary-bookkeeping-report-status-bookkept-with-warning-color: var(--badge-warning-text-color);

  --payout-summary-bookkeeping-report-status-failed-to-bookkeep-bg: var(--badge-danger-bg-color);
  --payout-summary-bookkeeping-report-status-failed-to-bookkeep-color: var(--badge-danger-text-color);

  --payout-summary-bookkeeping-report-status-saved-bg: var(--badge-success-bg-color);
  --payout-summary-bookkeeping-report-status-saved-color: var(--badge-success-text-color);

  --payout-summary-bookkeeping-report-status-saved-with-warning-bg: var(--badge-warning-bg-color);
  --payout-summary-bookkeeping-report-status-saved-with-warning-color: var(--badge-warning-text-color);
}
