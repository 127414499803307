.tooltip {
  --tooltip-border-width: 0;
  --tooltip-border-radius: var(--border-radius-sm);
  --tooltip-spacer-y: 0.625rem;
  --tooltip-spacer-x: 0.625rem;

  position: relative;
  padding: var(--tooltip-spacer-y) var(--tooltip-spacer-x);
  background-color: var(--tooltip-bg);
  border: var(--tooltip-border-width) solid var(--tooltip-border);
  border-radius: var(--tooltip-border-radius);
  filter: drop-shadow(var(--tooltip-box-shadow));
  white-space: nowrap;
  font-size: 0.75rem;
  color: var(--tooltip-color);

  &::before {
    content: "";
    pointer-events: none;
    position: absolute;
    transform-origin: center center;
    z-index: 1;
    display: none;
    width: 0.6875rem;
    height: 0.6875rem;
    border-top: var(--tooltip-border-width) solid var(--tooltip-border);
    border-left: var(--tooltip-border-width) solid var(--tooltip-border);
    background-color: var(--tooltip-bg);
  }
}

.center-top,
.center-bottom,
.start-center,
.end-center {
  .tooltip {
    &::before {
      display: block;
    }
  }
}

.center-top,
.center-bottom {
  .tooltip {
    &::before {
      left: 50%;
    }
  }
}

.center-top {
  .tooltip {
    &::before {
      top: 0;
      transform: translate3d(-50%, -33%, 0) rotate(0.125turn);
    }
  }
}

.center-bottom {
  .tooltip {
    &::before {
      top: 100%;
      transform: translate3d(-50%, -66%, 0) rotate(0.125turn);
    }
  }
}

.start-center,
.end-center {
  .tooltip {
    &::before {
      top: 50%;
    }
  }
}

.start-center {
  .tooltip {
    &::before {
      left: 0;
      transform: translate3d(-33%, -50%, 0) rotate(0.125turn);
    }
  }
}

.end-center {
  .tooltip {
    &::before {
      right: 0;
      transform: translate3d(33%, -50%, 0) rotate(0.125turn);
    }
  }
}
