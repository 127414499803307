:root {
  --huge-icon-size: 9.75rem;
  --huge-icon-border-width: 1.125rem;

  --huge-icon-close-bg: var(--danger);
  --huge-icon-close-border: #{mix(#E62222, #FFFFFF, 50%)};
  --huge-icon-close-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 152 152'><path fill='#{str-replace('#FFFFFF', "#", "%23")}' d='m35.8 41.149 5.3487-5.3487 34.851 34.851 34.851-34.851 5.3487 5.3487-34.851 34.851 34.851 34.851-5.3487 5.3488-34.851-34.851-34.851 34.851-5.3487-5.3488 34.851-34.851z'/></svg>");

  --huge-icon-check-bg: var(--primary);
  --huge-icon-check-border: hsl(var(--primary-h) var(--primary-s) min(calc(var(--primary-l) + 12%), 100%));
  --huge-icon-check-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 152 152'><path fill='#{str-replace('#FFFFFF', '#', '%23')}' d='m69.122 98.93-26.365-26.37 5.3495-5.3504 21.016 20.637 34.772-34.778 5.3495 5.3504z'/></svg>");

  --huge-icon-plus-bg: var(--primary);
  --huge-icon-plus-border: hsl(var(--primary-h) var(--primary-s) min(calc(var(--primary-l) + 12%), 100%));
  --huge-icon-plus-bg-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 152 152'><path fill='#{str-replace('#FFFFFF', '#', '%23')}' d='m72.218 22.931 7.5642-3.3e-5 1e-6 49.287 49.287 6.8e-5 -4e-5 7.5642-49.287 3.7e-5 -3e-6 49.287-7.5643 7e-5 4e-6 -49.287-49.287 4.3e-5 6.7e-5 -7.5643 49.287 3.1e-5z'/></svg>");
}
