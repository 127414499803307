//
// Base styles
//

.btn {
  display: inline-block;
  line-height: var(--line-height-base);
  padding: $btn-padding-y $btn-padding-x;
  vertical-align: middle;
  user-select: none;
  cursor: pointer;
  border-width: 1px;
  border-style: solid;
  border-radius: var(--btn-border-radius);
  font-size: 0.875rem;
  text-align: center;
  text-decoration: none;

  @include transition($btn-transition);

  border-color: var(--btn-border);
  background-color: var(--btn-bg);
  box-shadow: var(--btn-box-shadow);
  font-weight: var(--btn-font-weight);
  color: var(--btn-color);

  min-height: 2.625rem;

  &:hover {
    border-color: var(--btn-hover-border);
    background-color: var(--btn-hover-bg);
    color: var(--btn-hover-color);
  }

  .btn-check:focus-visible+&,
  &:focus-visible {
    box-shadow: var(--btn-focus-box-shadow);
    outline: 0;
  }

  .btn-check:checked+&,
  .btn-check.active+&,
  .btn-check:active+&,
  &.active,
  &:active {
    color: var(--btn-active-color);
    background-color: var(--btn-active-bg);
    border-color: var(--btn-active-border);

    &:focus {
      box-shadow: var(--btn-active-box-shadow);
    }
  }

  &:disabled,
  &.disabled,
  fieldset:disabled & {
    color: var(--btn-disabled-color);
    background-color: var(--btn-disabled-bg);
    border-color: var(--btn-disabled-border);
    pointer-events: none;
    box-shadow: none;
  }
}


//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
@each $type in $btn-theme-colors {
  .btn-#{$type} {
    --btn-bg: var(--btn-#{$type}-bg);
    --btn-border: var(--btn-#{$type}-border);
    --btn-color: var(--btn-#{$type}-color);
    --btn-box-shadow: var(--btn-#{$type}-box-shadow);
    --btn-font-weight: var(--btn-#{$type}-font-weight);

    --btn-hover-bg: var(--btn-#{$type}-hover-bg);
    --btn-hover-border: var(--btn-#{$type}-hover-border);
    --btn-hover-color: var(--btn-#{$type}-hover-color);

    --btn-active-bg: var(--btn-#{$type}-active-bg);
    --btn-active-border: var(--btn-#{$type}-active-border);
    --btn-active-color: var(--btn-#{$type}-active-color);
    --btn-active-box-shadow: var(--btn-#{$type}-active-box-shadow);

    --btn-focus-box-shadow: var(--btn-#{$type}-focus-box-shadow);

    --btn-disabled-bg: var(--btn-#{$type}-disabled-bg);
    --btn-disabled-border: var(--btn-#{$type}-disabled-border);
    --btn-disabled-color: var(--btn-#{$type}-disabled-color);
  }
}

// scss-docs-end btn-variant-loops


.btn-lg {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
