.dropdown {
  --dropdown-border-width: 0;
  --dropdown-border-radius: var(--border-radius);
  --dropdown-spacer-y: 0.7875rem;
  --dropdown-spacer-x: 0.7875rem;
  --dropdown-item-spacer-y: 0.25rem;
  --dropdown-item-spacer-x: var(--dropdown-spacer-x);
  --dropdown-caption-spacer-y: var(--dropdown-item-spacer-y);
  --dropdown-caption-spacer-x: var(--dropdown-item-spacer-x);
  --dropdown-footer-spacer-y: 0.5rem;

  position: relative;
  padding: var(--dropdown-spacer-y) var(--dropdown-spacer-x);
  background-color: var(--dropdown-bg);
  border: var(--dropdown-border-width) solid var(--dropdown-border);
  border-radius: var(--dropdown-border-radius);
  filter: drop-shadow(var(--dropdown-box-shadow));
  overflow: hidden;
  white-space: nowrap;
  color: var(--dropdown-color);

  &-divider {
    height: 1px;
    margin: var(--dropdown-spacer-y) calc(var(--dropdown-spacer-x) * -1);
    background-color: var(--dropdown-divider-color);
  }

  &-caption {
    line-height: 1.28571;
    padding: var(--dropdown-caption-spacer-y) var(--dropdown-caption-spacer-x);
    margin: 0 calc(var(--dropdown-spacer-x) * -1);
    font-weight: 600;
  }

  &-item {
    line-height: 1.28571;
    padding: var(--dropdown-item-spacer-y) var(--dropdown-item-spacer-x);
    margin: 0 calc(var(--dropdown-spacer-x) * -1);
    cursor: pointer;
    background-color: var(--dropdown-item-bg);
    color: var(--dropdown-item-color);

    &:hover,
    &:focus {
      background-color: var(--dropdown-item-hover-bg);
      text-decoration: none;
      color: var(--dropdown-item-hover-color);
    }

    &.active,
    &:active {
      background-color: var(--dropdown-item-active-bg);
      text-decoration: none;
      color: var(--dropdown-item-active-color);
    }
  }

  &-footer {
    padding-top: var(--dropdown-footer-spacer-y);
    padding-bottom: var(--dropdown-footer-spacer-y);
  }
}

.dropdown-menu {
  --dropdown-spacer-y: 0.75rem;
  --dropdown-spacer-x: 1.25rem;
}
