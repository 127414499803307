// This mixin uses an `if()` technique to be compatible with Dart Sass
// See https://github.com/sass/sass/issues/1873#issuecomment-152293725 for more details

// scss-docs-start form-validation-mixins
@mixin form-validation-state-selector($state) {
  @if ($state == "valid" or $state == "invalid") {
    .was-validated #{if(&, "&", "")}:#{$state},
    #{if(&, "&", "")}.is-#{$state} {
      @content;
    }
  } @else {
    #{if(&, "&", "")}.is-#{$state} {
      @content;
    }
  }
}

@mixin form-validation-state($state) {
  .form-control {
    @include form-validation-state-selector($state) {
      border-color: var(--form-feedback-#{$state});

      &:focus {
        border-color: var(--form-feedback-#{$state});
      }
    }
  }

  .form-select {
    @include form-validation-state-selector($state) {
      border-color: var(--form-feedback-#{$state});

      &:focus {
        border-color: var(--form-feedback-#{$state});
      }
    }
  }

  .form-check-input {
    @include form-validation-state-selector($state) {
      border-color: var(--form-feedback-#{$state});

      &:checked {
        background-color: var(--form-feedback-#{$state});
      }

      ~ .form-check-label {
        color: var(--form-feedback-#{$state});
      }
    }
  }

  .input-group .form-control,
  .input-group .form-select {
    @include form-validation-state-selector($state) {
      @if $state == "valid" {
        z-index: 1;
      } @else if $state == "invalid" {
        z-index: 2;
      }
      &:focus {
        z-index: 3;
      }
    }
  }
}
// scss-docs-end form-validation-mixins
