//
// Basic Bootstrap table
//

.table {
  width: 100%;
  vertical-align: top;
  border-color: var(--table-border);
  color: var(--table-color);

  // Target th & td
  // We need the child combinator to prevent styles leaking to nested tables which doesn't have a `.table` class.
  // We use the universal selectors here to simplify the selector (else we would need 6 different selectors).
  // Another advantage is that this generates less code and makes the selector less specific making it easier to override.
  // stylelint-disable-next-line selector-max-universal
  > :not(caption) > * > * {
    &.minimal-column {
      width: 1%;
      white-space: nowrap;
    }
  }

  > :not(caption, thead) > * > * {
    padding: var(--table-cell-padding-y) var(--table-cell-padding-x);
  }

  > tbody {
    vertical-align: inherit;
  }

  > thead {
    vertical-align: bottom;
    color: var(--table-thead-color);

    > * > * {
      padding: var(--table-thead-cell-padding-y) var(--table-thead-cell-padding-x);
      border-bottom: 1px solid var(--table-border) !important; // more selector's weight
    }
  }

  // Highlight border color between thead, tbody and tfoot.
  // > :not(:first-child) {
  //   border-top: 2px solid $table-group-separator-color;
  // }
}


// Border versions
//
// Add or remove borders all around the table and between all the columns.
//
// When borders are added on all sides of the cells, the corners can render odd when
// these borders do not have the same color or if they are semi-transparent.
// Therefor we add top and border bottoms to the `tr`s and left and right borders
// to the `td`s or `th`s

.table-bordered {
  > :not(caption) > * {
    border-width: 1px 0;

    // stylelint-disable-next-line selector-max-universal
    > * {
      border-width: 0 1px;
    }
  }
}

.table-row-bordered {
  // stylelint-disable-next-line selector-max-universal
  > thead > * > * {
    border-bottom-width: 1px;
  }

  > tbody > *:not(:first-child) {
    // stylelint-disable-next-line selector-max-universal
    > * {
      border-top-width: 1px;
    }
  }
}

.table-borderless {
  // stylelint-disable-next-line selector-max-universal
  > :not(caption) > * > * {
    border-bottom-width: 0;
  }

  > :not(:first-child) {
    border-top-width: 0;
  }
}

.table-condensed {
  --table-cell-padding-y: var(--table-condensed-cell-padding-y);
  --table-cell-padding-x: var(--table-condensed-cell-padding-x);
}

.table-hover-accent-row {
  > tbody {
    > tr {
      &:hover {
        background-color: var(--table-accent-bg);
        color: var(--table-accent-color);
      }
    }
  }
}

.table-striped {
  > tbody > tr:nth-of-type(odd) > * {
    background-color: var(--table-accent-bg);
    color: var(--table-accent-color);
  }
}

.table {
  > tbody {
    > th.accent,
    > td.accent,
    > tr.accent {
      background-color: var(--table-accent-bg);
      color: var(--table-accent-color);
    }
  }
}


// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

@each $breakpoint in map-keys($grid-breakpoints) {
  $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

  @include media-breakpoint-down($breakpoint) {
    .table-responsive#{$infix} {
      overflow-x: auto;
      -webkit-overflow-scrolling: touch;
    }
  }
}
