:root {
  --notifications-popover-bg: var(--modal-bg);
  --notifications-popover-border: var(--modal-border);
  --notifications-popover-border-radius: var(--modal-border-radius);
  --notifications-popover-color: var(--modal-color);
  --notifications-popover-box-shadow: var(--elevation-level-4);
  --notifications-popover-close-btn-color: var(--modal-close-btn-color);
  --notifications-popover-mark-as-read-btn-color: var(--success);
  --notifications-popover-delete-btn-color: var(--text-muted);
}
