//
// Base styles
//

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap; // For form validation feedback
  align-items: stretch;
  width: 100%;

  > .form-control-with-icons,
  > .form-control,
  > .form-select {
    position: relative; // For focus state's z-index
    flex: 1 1 auto;
    width: 1%;
    min-width: 0; // https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size
  }

  // Bring the "active" form control to the top of surrounding elements
  > .form-control-with-icons > .form-control:focus,
  > .form-control-with-icons > .form-select:focus,
  > .form-control:focus,
  > .form-select:focus {
    z-index: 3;
  }

  // Ensure buttons are always above inputs for more visually pleasing borders.
  // This isn't needed for `.input-group-text` since it shares the same border-color
  // as our inputs.
  .btn {
    position: relative;
    z-index: 2;

    &:focus {
      z-index: 3;
    }
  }
}



// Rounded corners
//
// These rulesets must come after the sizing ones to properly override sm and lg
// border-radius values when extending. They're more specific than we'd like
// with the `.input-group >` part, but without it, we cannot override the sizing.

// stylelint-disable-next-line no-duplicate-selectors
.input-group {
  &:not(.has-validation) {
    > .form-control-with-icons:not(:last-child) > .form-control,
    > .form-control-with-icons:not(:last-child) > .form-select,
    > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
    > .dropdown-toggle:nth-last-child(n + 3) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.has-validation {
    > :nth-last-child(n + 3):not(.dropdown-toggle):not(.dropdown-menu),
    > .dropdown-toggle:nth-last-child(n + 4) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  > .form-control-with-icons:not(:first-child) > .form-control,
  > .form-control-with-icons:not(:first-child) > .form-select,
  > :not(:first-child):not(.dropdown-menu) {
    margin-left: -$input-border-width;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
