:root {
  --alert-space-x: 1rem;
  --alert-space-y: 1rem;
  --alert-gap: 1rem;
  --alert-header-gap: 0.5rem;
  --alert-heading-line-height: 1.16667;
  --alert-heading-font-size: 1.125rem;
  --alert-heading-font-weight: 600;
  --alert-border-radius: var(--border-radius);
  --alert-icon-size: 1.25rem;

  --alert-bordered-border-width: 1px;
  --alert-embedded-unindented-header-gap: 1rem;

  --alert-neutral-bg-color: #F5F6F8;
  --alert-neutral-border-color: #F5F6F8;
  --alert-neutral-text-color: var(--text-color-3);
  --alert-neutral-heading-color: var(--text-color-2);
  --alert-neutral-icon-color: #F2A50C;

  --alert-success-bg-color: #E4FEE4;
  --alert-success-border-color: #ACD1AC;
  --alert-success-text-color: #084E23;
  --alert-success-heading-color: #084E23;
  --alert-success-icon-color: #219F51;

  --alert-danger-bg-color: #FEF2F2;
  --alert-danger-border-color: #CCC2C2;
  --alert-danger-text-color: #B31B1B;
  --alert-danger-heading-color: #B31B1B;
  --alert-danger-icon-color: #E62222;

  --alert-warning-bg-color: #FFFBE6;
  --alert-warning-border-color: #E6E2CF;
  --alert-warning-text-color: #B35009;
  --alert-warning-heading-color: #B35009;
  --alert-warning-icon-color: #F2A50C;

  --alert-info-bg-color: #E6F3FF;
  --alert-info-border-color: #BDCBF8;
  --alert-info-text-color: #1B41B3;
  --alert-info-heading-color: #1B41B3;
  --alert-info-icon-color: #3C88F2;
}
