:root {
  --modal-backdrop-bg: rgba(0, 0, 0, 0.2);

  --modal-bg: #FFFFFF;
  --modal-border: transparent;
  --modal-color: var(--text-color-3);
  --modal-border-radius: var(--border-radius);

  --modal-header-border: var(--border-color);
  --modal-footer-border: var(--modal-header-border);

  --modal-close-btn-color: var(--text-color-1);

  --modal-box-shadow: var(--elevation-level-4);
}
