//
// Labels
//

.form-label {
  margin-bottom: $form-label-margin-bottom;
  font-size: $form-label-font-size;
  font-style: $form-label-font-style;
  font-weight: $form-label-font-weight;
  color: var(--form-label-color);

  &[for] {
    cursor: pointer;
  }
}

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
  padding-top: add($input-padding-y, $input-border-width);
  padding-bottom: add($input-padding-y, $input-border-width);
  margin-bottom: 0; // Override the `<legend>` default
  font-size: inherit; // Override the `<legend>` default
  font-style: $form-label-font-style;
  font-weight: $form-label-font-weight;
  line-height: $input-line-height;
  color: var(--form-label-color);
}
