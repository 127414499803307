:root {
  --toasts-gap: 1rem;
  --toasts-indent-x: 2rem;
  --toasts-indent-y: 2rem;

  --toast-box-shadow: var(--elevation-level-4);
  --toast-border-radius: var(--border-radius);

  --toast-default-bg: rgba(0, 0, 18, 0.8);
  --toast-default-status-color: #707070;
  --toast-default-heading-color: #FFFFFF;
  --toast-default-content-color: #E6E6E6;
  --toast-default-content-link-color: #00CFF2;
  --toast-default-close-btn-color: #E6E6E6;
  --toast-default-autoclose-progress-bg: rgba(255, 255, 255, 0.25);
  --toast-default-color-scheme: dark; // to apply correct colors to scrollbars

  --toast-success-bg: var(--toast-default-bg);
  --toast-success-status-color: #10B981;
  --toast-success-heading-color: var(--toast-default-heading-color);
  --toast-success-content-color: var(--toast-default-content-color);
  --toast-success-content-link-color: var(--toast-default-content-link-color);
  --toast-success-close-btn-color: var(--toast-default-close-btn-color);
  --toast-success-autoclose-progress-bg: var(--toast-default-autoclose-progress-bg);
  --toast-success-color-scheme: var(--toast-default-color-scheme);

  --toast-info-bg: var(--toast-default-bg);
  --toast-info-status-color: #E6E6E6;
  --toast-info-heading-color: var(--toast-default-heading-color);
  --toast-info-content-color: var(--toast-default-content-color);
  --toast-info-content-link-color: var(--toast-default-content-link-color);
  --toast-info-close-btn-color: var(--toast-default-close-btn-color);
  --toast-info-autoclose-progress-bg: var(--toast-default-autoclose-progress-bg);
  --toast-info-color-scheme: var(--toast-default-color-scheme);

  --toast-warning-bg: var(--toast-default-bg);
  --toast-warning-status-color: #F2A50C;
  --toast-warning-heading-color: var(--toast-default-heading-color);
  --toast-warning-content-color: var(--toast-default-content-color);
  --toast-warning-content-link-color: var(--toast-default-content-link-color);
  --toast-warning-close-btn-color: var(--toast-default-close-btn-color);
  --toast-warning-autoclose-progress-bg: var(--toast-default-autoclose-progress-bg);
  --toast-warning-color-scheme: var(--toast-default-color-scheme);

  --toast-error-bg: var(--toast-default-bg);
  --toast-error-status-color: #E62222;
  --toast-error-heading-color: var(--toast-default-heading-color);
  --toast-error-content-color: var(--toast-default-content-color);
  --toast-error-content-link-color: var(--toast-default-content-link-color);
  --toast-error-close-btn-color: var(--toast-default-close-btn-color);
  --toast-error-autoclose-progress-bg: var(--toast-default-autoclose-progress-bg);
  --toast-error-color-scheme: var(--toast-default-color-scheme);
}
