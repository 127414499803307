@import "core/functions";

@import "bootstrap-variables";

// Helpers
@import "core/mixins/breakpoints";
@import "core/mixins/image";
@import "core/mixins/resize";
@import "core/mixins/visually-hidden";
@import "core/mixins/text-truncate";

// Utilities
@import "core/mixins/utilities";

// Components
@import "core/mixins/lists";
@import "core/mixins/forms";

// Skins
@import "core/mixins/transition";

// Layout
@import "core/mixins/container";
@import "core/mixins/grid";



@import "bootstrap-utilities-variables";

$btn-theme-colors: (
  "primary",
  "danger",
  "danger-outline",
  "outline",
  "outline2",
  "link",
);

$toast-theme-colors: (
  "neutral",
  "success",
  "error",
  "warning",
);

$zindex-blocking-loader: $zindex-modal + 5; // 1060
$zindex-logging-out-blocking-loader: $zindex-blocking-loader + 5; // 1065
