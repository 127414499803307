:root {
  --dropdown-bg: #FFFFFF;
  --dropdown-border: transparent;
  --dropdown-color: var(--body-color);
  --dropdown-divider-color: var(--table-border);

  // Used as 'filter drop-shadow' so it has not 5th parameter
  --dropdown-box-shadow: var(--elevation-level-2);

  --dropdown-item-bg: trasparent;
  --dropdown-item-color: var(--dropdown-color);
  --dropdown-item-hover-bg: #EEEEEE;
  --dropdown-item-hover-color: var(--dropdown-color);
  --dropdown-item-active-bg: #E3E3E3;
  --dropdown-item-active-color: var(--dropdown-color);
}
