google-chart {
  svg {
    // Chart bg
    > rect {
      fill: var(--chart-bg);
    }

    > g {
      > g:nth-of-type(1) {
        // Gridlines
        > g:nth-of-type(1) rect {
          fill: var(--chart-vAxis-gridline-color);
        }
      }

      // Text
      > g:nth-of-type(3) text {
        fill: var(--chart-color);
      }
    }
  }

  &.google-chart_line-chart {
    svg {
      > g {
        > g:nth-of-type(1) {
          // Baseline
          > g:nth-of-type(2) rect {
            fill: var(--chart-vAxis-baseline-color);
          }
        }
      }
    }
  }

  &.google-chart_column-chart {
    svg {
      > g {
        > g:nth-of-type(1) {
          // Baseline
          > g:nth-of-type(3) rect {
            fill: var(--chart-vAxis-baseline-color);
          }
        }
      }
    }
  }
}

// Make selector's weight bigger
body .google-visualization-tooltip {
  --google-visualization-tooltip-gap: 0.5rem;

  position: absolute;
  z-index: 10000;
  padding: 0;
  border: 0;
  border-radius: var(--border-radius);
  background-color: var(--inverted-bg);
  box-shadow: none;
  color: var(--inverted-color);
  font-size: 0.75rem;

  &-inner {
    padding: var(--google-visualization-tooltip-gap);

    .accent {
      font-size: 1.125rem;
      font-weight: 700;
    }
  }
}
