// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--nav-tabs-link-padding-y) var(--nav-tabs-link-padding-x);
  font-weight: 600;
  color: var(--nav-link-color);
  text-decoration: none;
  @include transition($nav-link-transition);

  &:hover,
  &:focus {
    color: var(--nav-link-hover-color);
  }

  // Disabled state lightens text
  &.disabled {
    color: var(--nav-link-disabled-color);
    pointer-events: none;
    cursor: default;
  }
}

//
// Tabs
//

.nav-tabs {
  gap: var(--nav-tabs-items-gap);

  .nav-link {
    margin-bottom: calc(var(--nav-tabs-link-border-width) * -1);
    background: none;
    border-bottom: var(--nav-tabs-link-border-width) solid var(--nav-tabs-border);
    font-size: var(--h5-font-size);
    font-weight: 600;
    text-transform: uppercase;

    &:hover {
      border-color: var(--nav-tabs-link-hover-border);
      // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
      isolation: isolate;
    }

    &:focus {
      border-color: var(--nav-tabs-link-focus-border);
      // Prevents active .nav-link tab overlapping focus outline of previous/next .nav-link
      isolation: isolate;
    }

    &.disabled {
      color: var(--nav-link-disabled-color);
      background-color: transparent;
      border-color: transparent;
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    border-color: var(--nav-tabs-link-active-border);
    background-color: var(--nav-tabs-link-active-bg);
    color: var(--nav-tabs-link-active-color);
  }

  .dropdown-menu {
    // Make dropdown border overlap tab border
    margin-top: calc(var(--nav-tabs-link-border-width) * -1);
    // Remove the top rounded corners here since there is a hard edge above the menu
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}


//
// Justified variants
//

.nav-fill {
  > .nav-link,
  .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
}

.nav-justified {
  > .nav-link,
  .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
}

.nav-fill,
.nav-justified {
  .nav-item .nav-link {
    width: 100%; // Make sure button will grow
  }
}


// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
  > .tab-pane {
    display: none;
  }
  > .active {
    display: block;
  }
}
