:root {
  --btn-bg: transparent;
  --btn-border: transparent;
  --btn-border-radius: var(--border-radius);
  --btn-color: var(--body-bg);
  --btn-box-shadow: none;
  --btn-font-weight: 500;

  --btn-hover-bg: transparent;
  --btn-hover-border: transparent;
  --btn-hover-color: var(--body-bg);

  --btn-active-bg: transparent;
  --btn-active-border: transparent;
  --btn-active-color: var(--body-bg);
  --btn-active-box-shadow: none;

  --btn-focus-box-shadow: 0 0 0 4px hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.25);

  --btn-disabled-bg: #F3F4F6;
  --btn-disabled-border: #E5E7EB;
  --btn-disabled-color: #9CA3AF;
}

.btn-primary {
  --btn-primary-bg: var(--primary);
  --btn-primary-border: var(--primary);
  --btn-primary-color: #FFFFFF;
  --btn-primary-font-weight: 700;
  --btn-primary-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  --btn-primary-hover-bg: hsl(var(--primary-h) var(--primary-s) min(calc(var(--primary-l) + 8%), 100%));
  --btn-primary-hover-border: hsl(var(--primary-h) var(--primary-s) min(calc(var(--primary-l) + 8%), 100%));
  --btn-primary-hover-color: #FFFFFF;

  --btn-primary-active-bg: hsl(var(--primary-h) var(--primary-s) min(calc(var(--primary-l) + 8%), 100%));
  --btn-primary-active-border: hsl(var(--primary-h) var(--primary-s) min(calc(var(--primary-l) + 8%), 100%));
  --btn-primary-active-color: #FFFFFF;
  --btn-primary-active-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.125);

  --btn-primary-focus-box-shadow: 0 0 0 4px hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.25);

  --btn-primary-disabled-bg: hsl(0 0% 70%);
  --btn-primary-disabled-border: hsl(0 0% 70%);
  --btn-primary-disabled-color: #4B5563;
}

.btn-danger {
  --btn-danger-bg: #E62222;
  --btn-danger-border: #E62222;
  --btn-danger-color: #FFFFFF;
  --btn-danger-font-weight: 500;
  --btn-danger-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  --btn-danger-hover-bg: #B31B1B;
  --btn-danger-hover-border: #B31B1B;
  --btn-danger-hover-color: #FFFFFF;

  --btn-danger-active-bg: #B31B1B;
  --btn-danger-active-border: #B31B1B;
  --btn-danger-active-color: #FFFFFF;
  --btn-danger-active-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.125);

  --btn-danger-focus-box-shadow: 0 0 0 4px rgba(var(--danger-rgb), 0.25);

  --btn-danger-disabled-bg: #F3F4F6;
  --btn-danger-disabled-border: #E5E7EB;
  --btn-danger-disabled-color: #9CA3AF;
}

.btn-danger-outline {
  --btn-danger-outline-bg: #FFFFFF;
  --btn-danger-outline-border: #E62222;
  --btn-danger-outline-color: #E62222;
  --btn-danger-outline-font-weight: 500;
  --btn-danger-outline-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  --btn-danger-outline-hover-bg: #FFFFFF;
  --btn-danger-outline-hover-border: #B31B1B;
  --btn-danger-outline-hover-color: #B31B1B;

  --btn-danger-outline-active-bg: #FFFFFF;
  --btn-danger-outline-active-border: #B31B1B;
  --btn-danger-outline-active-color: #B31B1B;
  --btn-danger-outline-active-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.125);

  --btn-danger-outline-focus-box-shadow: 0 0 0 4px rgba(var(--danger-rgb), 0.25);

  --btn-danger-outline-disabled-bg: #F3F4F6;
  --btn-danger-outline-disabled-border: #E5E7EB;
  --btn-danger-outline-disabled-color: #9CA3AF;
}

.btn-outline {
  --btn-outline-bg: #FFFFFF;
  --btn-outline-border: #E5E7EB;
  --btn-outline-color: #4B5563;
  --btn-outline-font-weight: 500;
  --btn-outline-box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.05);

  --btn-outline-hover-bg: #F9FAFB;
  --btn-outline-hover-border: #E5E7EB;
  --btn-outline-hover-color: #4B5563;

  --btn-outline-active-bg: #F9FAFB;
  --btn-outline-active-border: #E5E7EB;
  --btn-outline-active-color: #4B5563;
  --btn-outline-active-box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.125);

  --btn-outline-focus-box-shadow: 0 0 0 4px hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.25);

  --btn-outline-disabled-bg: #F3F4F6;
  --btn-outline-disabled-border: #E5E7EB;
  --btn-outline-disabled-color: #9CA3AF;
}

.btn-outline2 {
  --btn-outline2-bg: #FFFFFF;
  --btn-outline2-border: #4B5563;
  --btn-outline2-color: #4B5563;
  --btn-outline2-font-weight: 500;
  --btn-outline2-box-shadow: none;

  --btn-outline2-hover-bg: #F9FAFB;
  --btn-outline2-hover-border: #4B5563;
  --btn-outline2-hover-color: #4B5563;

  --btn-outline2-active-bg: #4B5563;
  --btn-outline2-active-border: #4B5563;
  --btn-outline2-active-color: #FFFFFF;
  --btn-outline2-active-box-shadow: none;

  --btn-outline2-focus-box-shadow: 0 0 0 4px hsla(var(--primary-h), var(--primary-s), var(--primary-l), 0.25);

  --btn-outline2-disabled-bg: #F3F4F6;
  --btn-outline2-disabled-border: #9CA3AF;
  --btn-outline2-disabled-color: #9CA3AF;
}

.btn-link {
  --btn-link-bg: transparent;
  --btn-link-border: transparent;
  --btn-link-color: var(--link-color);
  --btn-link-font-weight: 400;
  --btn-link-box-shadow: none;

  --btn-link-hover-bg: transparent;
  --btn-link-hover-border: transparent;
  --btn-link-hover-color: var(--link-hover-color);

  --btn-link-active-bg: transparent;
  --btn-link-active-border: transparent;
  --btn-link-active-color: var(--link-hover-color);
  --btn-link-active-box-shadow: none;

  --btn-link-focus-box-shadow: none;

  --btn-link-disabled-bg: transparent;
  --btn-link-disabled-border: transparent;
  --btn-link-disabled-color: #BBBBBB;
}
